<template>
  <section class="table_box edit_product">
    <div class="container flx">
      <div class="table_head flx">
        <span class="page_title">Новый товар</span>
        <div class="btn empty open_pop" data-id="history_change">История изменений (todo)</div>
        <span class="del_wrap"><span class="close"> </span><span class="red">Удалить товар</span></span>
      </div>
      <div class="wrap_btn flx">
        <a @click="$router.go(-1)" href="#" class="back flx">
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.97814 0.796948C6.28105 1.09986 6.28105 1.59098 5.97814 1.89389L1.87267 5.99936L5.97814 10.1048C6.28105 10.4077 6.28105 10.8989 5.97814 11.2018C5.67522 11.5047 5.18411 11.5047 4.88119 11.2018L0.227251 6.54783C-0.0756619 6.24492 -0.0756619 5.7538 0.227251 5.45089L4.88119 0.796948C5.18411 0.494035 5.67522 0.494035 5.97814 0.796948Z"
                  fill="#F5A32A"/>
          </svg>
          <span>Назад</span>
        </a>
        <div class="btn" :disabled="is_loading" @click="store(product_data)">Сохранить <brew-loader :show="is_loading"></brew-loader></div>
      </div>
    </div>
    <div class="container tbl flx">
      <div class="column">
        <div class="edit_description box_shadow">
          <p>Название товара:</p>
          <input v-model="product_data.name" :class="{required:hasError('name')}" type="text" required>
<!--          <p>Сокращенное название для Новой Почты:</p>-->
<!--          <input v-model="product_data.short_name" :class="{required:hasError('short_name')}" type="text" required>-->
          <p>Описание товара</p>
          <div class="tab_content">
            <div class="tabs_box" data-type="description">
              <div class="tab" @click="activeTab=0" :class="{active:activeTab===0}">
                <p>Основное</p>
              </div>
              <div class="tab" @click="activeTab=1" :class="{active:activeTab===1}">
                <p>На Укр.</p>
              </div>
              <div class="tab" @click="activeTab=2" :class="{active:activeTab===2}">
                <p>в Instagram</p>
              </div>
              <div class="tab" @click="activeTab=3" :class="{active:activeTab===3}">
                <p>в Instagram на Укр.</p>
              </div><!-- tab -->
            </div><!-- tabs_box -->
            <div class="tab_container" :class="{active:activeTab===0}">
              <ckeditor :editor="editor" v-model="product_data.descriptions.description" :config="editorConfig"></ckeditor>
            </div>
            <div class="tab_container" :class="{active:activeTab===1}">
              <ckeditor :editor="editor" v-model="product_data.descriptions.description_ua" :config="editorConfig"></ckeditor>
            </div>
            <div class="tab_container" :class="{active:activeTab===2}">
              <ckeditor :editor="editor" v-model="product_data.descriptions.instagram" :config="editorConfig"></ckeditor>
            </div>
            <div class="tab_container" :class="{active:activeTab===3}">
              <ckeditor :editor="editor" v-model="product_data.descriptions.instagram_ua" :config="editorConfig"></ckeditor>
            </div>
          </div>
          <div class="inp_wrap">
            <p>Ссылка на видео:</p>
            <input type="text" value="youtube.com">
          </div>
        </div>
        <div class="view_wrap">
          <span class="view"></span>
          <a href="/">Просмотреть товар на сайте</a>
        </div>
      </div>
      <div class="edit_characteristics">
        <div class="box_shadow general_change">
          <div class="row">
            <div class="inp_wrap category_prod">
              <p>Категория товара:</p>
              <brew-select
                class="vue-select multiple"
                multiple
                label="path_name"
                v-model="product_data.category_ids"
                :reduce="item => item.id"
                :options="childCategories"
                :class="{required:hasError('category_ids')}"
              ></brew-select>
            </div>
          </div>
          <div class="row flx">
            <div class="left flx">
              <div class="inp_wrap">
                <p>Артикул</p>
                <input v-model="product_data.vendor_code" :class="{required:hasError('vendor_code')}" type="text" placeholder="Артикул">
              </div>
              <div>
                <p :class="{danger:hasError('season_ids')}">Выберите сезоны</p>
                <div class="checkbox_wrap flx">
                  <label
                    v-for="season in seasons"
                    :key="season.id"
                    class="custom-checkbox"
                  >
                    <input type="checkbox" v-model="product_data.season_ids"  :value="season.id">
                    <span></span>
                    <span>{{ season.name }}</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="flx">
              <div class="">
                <p>Вес:</p>
                <div class="select"  style="width: 100px">
                  <brew-select
                    class="vue-select"
                    label="name"
                    v-model="product_data.weight"
                    :options="getWeightList()"
                    :reduce="item => item.value"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <span class="page_subtitle">Цвета・Размеры・Количество </span>
        <create-product-colors
          v-for="(colorData, index) in product_data.remains"
          :key="colorData.id"
          :index="index"
          :product_color="colorData"
          :selected="product_data.remains"
          @change="setColorDataItem"
          @remove="removeItemColor"
        />
        <br>
        <div class="btn add_color" @click="addColorData">
          <span class="plus_light"></span>
          <span>Добавить новый цвет</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import CreateProductColors from '@/components/Droppshipping/Products/CreateProductColors'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import _ from 'lodash'
import BrewLoader from '../../../helpers/components/BrewLoader'

let colorKey = 0

export default {
  name: 'CreateProduct',
  components: { CreateProductColors, BrewLoader },
  data () {
    return {
      product_data: {
        name: null,
        short_name: null,
        vendor_code: null,
        category_ids: [],
        season_ids: [],
        remains: [],
        descriptions: {
          description: null,
          description_ua: null,
          instagram: null,
          instagram_ua: null
        }
      },
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        // The configuration of the editor.
      },
      activeTab: 0
    }
  },
  computed: {
    ...mapState('catalog', ['childCategories']),
    ...mapState('season', ['seasons']),
    ...mapState('size', ['sizes']),
    ...mapState('config', ['config']),
    ...mapState('errors', ['errors']),
    ...mapGetters('errors', ['hasErrors', 'hasError']),
    ...mapState('product', ['is_loading']),
    form_errors: {
      get () {
        return this.errors
      },
      set (err) {
        this.setErrors(err)
      }
    }
  },
  methods: {
    ...mapActions('catalog', ['getChildCategories']),
    ...mapActions('product', ['createProduct']),
    ...mapMutations('errors', ['setErrors', 'clearErrors']),
    addColorData () {
      const maxKey = _.maxBy(this.product_data.remains, 'id')
      colorKey = maxKey ? maxKey.id + 1 : 1
      this.product_data.remains.push({
        id: colorKey,
        color_id: null,
        purchase_price: 0,
        retail_price: 0,
        sizes: [],
        images: [],
        discount: {
          value: 0,
          type: '%'
        }
      })
    },
    setColorDataItem (data) {
      this.$set(this.product_data.remains, data.index, data.data)
    },
    removeItemColor (id) {
      this.$delete(this.product_data.remains, id)
    },
    getWeightList () {
      return this.config.product?.weight_list || []
    },
    store (data) {
      this.createProduct(data)
        .then(path => {
          this.$router.push({ name: path })
        })
    }
  },
  created () {
    this.getChildCategories()
    this.clearErrors()
  }
}
</script>

<style scoped>

</style>
